var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"color":"white"}},[_c('v-layout',{attrs:{"row":""}},[_c('v-card',{attrs:{"flat":""}},[_c('v-badge',{attrs:{"left":"","overlap":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [(_vm.getProbableData.length)?_c('v-btn',{staticClass:"elevation-0",staticStyle:{"margin-top":"-1.3em"},attrs:{"round":"","color":"primary","fab":"","small":""}},[_vm._v(_vm._s(_vm.getProbableData.length))]):_vm._e()]},proxy:true}])},[_c('v-card-text',{staticClass:"title"},[_vm._v("Probable Candidate List")])],1)],1),_c('v-divider',{staticClass:"my-2",attrs:{"vertical":""}}),_c('v-text-field',{staticClass:"mt-0",attrs:{"prepend-inner-icon":"search","label":"Search","hide-details":"","solo":"","flat":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":_vm.downloadPdf}},[_vm._v(" Export As PDF "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("cloud_download")])],1),_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":_vm.downloadCSV}},[_vm._v(" Export As CSV "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("cloud_download")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.getProbableData,"search":_vm.search,"rows-per-page-items":[
        25,
        50,
        100,
        { text: '$vuetify.dataIterator.rowsPerPageAll', value: -1 }
      ],"pagination":_vm.pagination},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('td',[_vm._v(_vm._s(props.index + 1))]),_c('td',{staticClass:"text-xs"},[_vm._v(_vm._s(props.item.student.reg_no))]),_c('td',{staticClass:"text-xs"},[_vm._v(_vm._s(props.item.student.ac_session))]),_c('td',{staticClass:"text-xs"},[_vm._v(_vm._s(props.item.student.name))]),_c('td',{staticClass:"text-xs"},[_vm._v(_vm._s(props.item.student.father_name))]),_c('td',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(_vm.getPaperCodes(props.item.papers))+" ")]),_c('td',{staticClass:"text-xs"},[_vm._v(_vm._s(props.item.student.mobile))]),_c('td',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(props.item.candi_date_type.candidate_type)+" ")]),_c('td',{staticClass:"text-xs"},[(
              Number(props.item.student_confirm_status) == 1 &&
                Number(props.item.teacher_confirm_status) == 1
            )?_c('v-chip',{attrs:{"small":"","color":"success","text-color":"white"}},[_vm._v("Confirmed")]):(Number(props.item.student_confirm_status) == 1)?_c('v-chip',{attrs:{"small":"","color":"info","text-color":"white"}},[_vm._v("Applied")]):_c('v-chip',{attrs:{"small":"","color":"red","text-color":"white"}},[_vm._v("Not Apply")])],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }